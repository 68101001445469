import * as React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import classNames from "classnames";
import Select from "react-select";
import Spinner from "./Spinner";

type Option = { value: string; label: string };
const options = [
  { value: "general", label: "General" },
  { value: "business", label: "Business" },
  { value: "developer", label: "Developer" },
  { value: "support", label: "Support" },
];

const ContactSection = () => {
  const { t } = useTranslation();
  const recaptchaRef = React.useRef<any>(null);
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [inquiry, setInquiry] = React.useState(options[0]);
  const [message, setMessage] = React.useState("");
  const [isSuccess, setSuccess] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const captchaToken = await recaptchaRef.current.getValue();

    const bodyObj = {
      email: name + " <" + email + ">",
      message: inquiry.label + " - " + message,
    };

    try {
      setLoading(true);
      setSuccess(false);
      await fetch("https://contact-service.milkomeda.com", {
        headers: {
          "Content-Type": "application/json",
        },
        mode: "no-cors", // 'cors' by default
        method: "POST",
        body: JSON.stringify({
          ...bodyObj,
          "captcha-token": captchaToken,
        }),
      });
      setSuccess(true);
      setName("");
      setEmail("");
      setMessage("");
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      recaptchaRef.current.reset();
      setIsSubmitEnabled(false);
    }
  };

  return (
    <>
      <section className="w-full relative z-20 -mb-10">
        <div className="absolute w-full h-full top-0 left-0 -z-10">
          <img
            className="w-full h-full object-cover"
            srcSet="/images/page-bg@2x.webp 2x, /images/page-bg.webp 1x"
            src="/images/page-bg.webp"
            alt="page-bg"
          />
        </div>
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className="container pt-110 lg:pt-185 sc-up-mb relative">
          <div className="text-center">
            <h1 className="heading-3 heading-1-2">
              <Trans>Get in touch with us</Trans>
            </h1>
          </div>
          <div className="space-custom"></div>
        </div>

        <div className="w-full -z-10">
          <img
            className="w-full"
            srcSet="/images/oval_BG@2x.webp 2x, /images/oval_BG.webp 1x"
            src="/images/oval_BG.webp"
            alt="oval-top"
          />
        </div>
      </section>

      <section className="relative">
        <div className="bg-blue-bg-dark section-custom-top">
          <div className="container relative z-10">
            <div className="mx-auto w-full max-w-[636px] relative z-20">
              <p className="text-24 font-semibold text-center">
                <Trans>Want to collaborate, or partner up? Get in touch.</Trans>
              </p>
              <form name="form 1" className="mt-50 mb-24" onSubmit={handleSubmit}>
                <input type="hidden" name="form-name" value="form 1" />
                <div className="input-custom">
                  <label htmlFor="name">
                    <Trans>Your name *</Trans>
                  </label>
                  <input
                    type="text"
                    name="name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="input-custom mt-15">
                  <label htmlFor="email">
                    <Trans>Email *</Trans>
                  </label>
                  <input
                    type="email"
                    name="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-custom mt-15">
                  <label htmlFor="inquiry-type">
                    <Trans>Inquiry type *</Trans>
                  </label>
                  <Select
                    options={options}
                    className="contact-inquiry-select"
                    classNamePrefix="react-select"
                    value={inquiry}
                    getOptionLabel={(item) => item.label}
                    getOptionValue={(item) => item.value}
                    onChange={(item) => {
                      setInquiry(item as Option);
                    }}
                  />
                </div>
                <div className="input-custom mt-15">
                  <label htmlFor="message">
                    <Trans>Message *</Trans>
                  </label>
                  <textarea
                    name="message"
                    rows={5}
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <div className="g-recaptcha">
                  <ReCAPTCHA
                    className="block"
                    ref={recaptchaRef}
                    sitekey={"6LexZ-ghAAAAAJxFUctUaWFJ1WlqG3l7oK0lBJsK"}
                    onChange={() => setIsSubmitEnabled(true)}
                    onExpired={() => setIsSubmitEnabled(false)}
                    onErrored={() => setIsSubmitEnabled(false)}
                  />
                  <button
                    className={classNames(
                      "btn-big mt-40 w-full",
                      isSubmitEnabled ? "" : "btn-disabled"
                    )}
                    type="submit"
                    disabled={!isSubmitEnabled}
                  >
                    {isLoading ? <Spinner /> : <Trans>Submit</Trans>}
                  </button>
                </div>
              </form>
              {isSuccess ? (
                <div className="flex items-start">
                  <img src="/images/icons/check-icon.svg" className="h-20 w-20" alt="" />
                  <p className="text-primary-color-900 font-base text-14 pl-6">
                    <Trans>
                      Thank you! We've received your message. Someone from out team will contact you
                      soon.
                    </Trans>
                  </p>
                </div>
              ) : (
                <div className="h-[40px]" />
              )}
            </div>
          </div>
        </div>
        <div className="container h-full absolute top-0 left-0 right-0 bottom-0">
          <div className="layout-grid z-10">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactSection;
